import React, { useState } from "react";
import "./TwoColumnTable.css";
import Loading from "./LoadingAnimation";
import InfoTooltip from "./InfoTooltip";

const SingleColumnTable = ({
  data = [],
  isLoaded = true,
  error = null,
  columnTitle = null,
  columnKey = "value",
  defaultNumberOfRows = 10,
  tooltipText = null,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(defaultNumberOfRows);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="kv-table-container">
      <table className="kv-table">
        <thead>
          <tr>
            <th>
              {columnTitle}
              {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
            </th>
          </tr>
        </thead>

        <tbody>
          {data.length === 0 ? (
            <div>
              <tr>
                {error ? (
                  <td> Sorry! An error occurred while fetching the data. </td>
                ) : isLoaded && data.length === 0 ? (
                  <td>No data available</td>
                ) : (
                  <td>
                    <Loading />
                  </td>
                )}
              </tr>
              {[...Array(rowsPerPage - 1)].map((_, index) => (
                <tr key={index}>
                  <td>&nbsp;</td>
                </tr>
              ))}
            </div>
          ) : (
            <>
              {currentData.map((row, index) => (
                <tr key={index}>
                  <td>{row[columnKey]}</td>
                </tr>
              ))}
              {Array.from({ length: rowsPerPage - currentData.length }).map(
                (_, i) => (
                  <tr key={`empty-${i}`}>
                    <td>&nbsp;</td>
                  </tr>
                )
              )}
            </>
          )}
        </tbody>
      </table>

      {data.length > 0 && (
        <div className="table-footer">
          <div className="pagination-controls">
            <button
              className="pagination-button"
              onClick={handlePrev}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <span className="page-info">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="pagination-button"
              onClick={handleNext}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>

          <div>
            <label>Rows per page: &nbsp; &nbsp; </label>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleColumnTable;
