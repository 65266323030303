import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import TopNavBar from "./TopNavBar";
import SearchBar from "./SearchBar";
import KeyValueTable from "./TwoColumnTable";
import DoughnutChart from "./DonutChart";
import BarChart from "./BarChart";
import InfoTooltip from "./InfoTooltip";
import useLoadData from "./useLoadData";
import QueryFilterTabs from "./QueryFilterTabs";
import TimeseriesChart from "./TimeseriesChart";
import SingleColumnTable from "./SingleColumnTable";

// const BACKEND_URL = "http://0.0.0.0:8000/api/v1";
const BACKEND_URL =
  "https://elelem-analytics-backend-2maapedxcq-uc.a.run.app/api/v1";

const Analytics = () => {
  const [startDate, setStartDate] = useState(new Date("2024-01-01"));
  const [endDate, setEndDate] = useState(new Date("2024-12-31"));
  const brand = "S&P";

  const formatDate = (date) => date.toISOString().split("T")[0];

  // First 2 tables
  const [GenAIApplicationTracker, trackerLoaded, trackerError] = useLoadData(
    `${BACKEND_URL}/GenAIApplicationTracker/${brand}?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`
  );

  // 3rd table on first row
  const [TrackerTimeseries, TimeseriesLoaded, TimeseriesError] = useLoadData(
    `${BACKEND_URL}/ApplicationTrackerTimeSeries/${brand}?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`
  );

  // 2nd row - 2 tables.
  const [TrackedQueries, queriesLoaded, queriesError] = useLoadData(
    `${BACKEND_URL}/ClusteredTrackedQueries/${brand}?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`
  );

  const clusterShares = [
    ...new Set(TrackedQueries.map((q) => q.cluster_name)),
  ].map((name) => ({
    cluster_name: name,
    share: TrackedQueries.filter((q) => q.cluster_name === name).length,
  }));

  // const clusterScores = [
  //   ...new Set(TrackedQueries.map((q) => q.cluster_name)),
  // ].map((name) => ({
  //   cluster_name: name,
  //   value:
  //     TrackedQueries.filter((q) => q.cluster_name === name).reduce(
  //       (sum, q) => sum + q.relevance_score,
  //       0
  //     ) / TrackedQueries.filter((q) => q.cluster_name === name).length,
  // }));

  // console.log(clusterScores);

  // const clusterPerformance = clusterScores.map((cluster) => ({
  //   cluster_name: cluster.cluster_name,
  //   value:
  //     cluster.value < 0.2
  //       ? "Irrelevant"
  //       : cluster.value >= 0.2 && cluster.value < 0.3
  //       ? "Somewhere relevant"
  //       : "Relevant",
  // }));

  const poorlyPerformedQueries = TrackedQueries.filter(
    (q) => q.relevance_score < 0.15
  );

  const somewhatPerformedQueries = TrackedQueries.filter(
    (q) => q.relevance_score >= 0.15 && q.relevance_score < 3
  );

  (async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
  })();

  const [ContentSuggestion, contentSuggestionLoaded, contentSuggestionError] =
    useLoadData(
      `${BACKEND_URL}/TopContentSuggestion/${brand}?start_date=${formatDate(
        startDate
      )}&end_date=${formatDate(endDate)}`
    );

  const [
    RelevanceTimeSeries,
    relevanceTimeSeriesLoaded,
    relevanceTimeSeriesError,
  ] = useLoadData(
    `${BACKEND_URL}/RelevanceScoreTimeSeries/${brand}?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`
  );

  const scaledRelevanceTimeSeries = RelevanceTimeSeries.map((item) => ({
    key: item.key,
    value: item.value * 100,
  }));

  const [TopPerformingPage, pagesLoaded, pagesError] = useLoadData(
    `${BACKEND_URL}/TopPerformingPage/${brand}?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`
  );

  const TopPerformingUrl = TopPerformingPage.map((item) => ({
    ...item,
    url: (
      <a
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black", textDecoration: "underline" }}
      >
        {item.title}
      </a>
    ),
    traffic: item.traffic,
  }));

  const [GenAIApplicationTraffic, trafficLoaded, trafficError] = useLoadData(
    `${BACKEND_URL}/GenAIApplicationTraffic/${brand}?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`
  );

  const [TrafficURLText, trafficURLLoaded, trafficURLError] = useLoadData(
    `${BACKEND_URL}/TrafficUrls/${brand}?start_date=${formatDate(
      startDate
    )}&end_date=${formatDate(endDate)}`
  );

  const TrafficURLs = TrafficURLText.map((item) => ({
    ...item,
    key: (
      <a
        href={item.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black", textDecoration: "underline" }}
      >
        {item.title}
      </a>
    ),
    value: item.traffic,
  }));

  useEffect(() => {
    console.log("UseEffect Is Called");
  }, [startDate, endDate]);

  return (
    <div>
      <SideBar current_page={"analytics"} />
      <div className="main-page">
        <TopNavBar />
        <SearchBar
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
        <div className="dashboard">
          <h1 style={{ "text-align": "center" }}> Elelem Analytics </h1>
          <div class="d-flex align-items-center">
            <h2 class="mb-0">AI Application Tracker</h2>{" "}
            <InfoTooltip
              tooltipText={
                "Discover which AI platforms use your content —from ChatGPT to brand-specific applications—giving you complete visibility into how your content is being leveraged by AI systems"
              }
            />
          </div>
          <div className="row d-flex" style={{ alignItems: "stretch" }}>
            <div className="col-md-5 margin-top_div">
              <DoughnutChart
                data={GenAIApplicationTracker}
                isLoaded={trackerLoaded}
                error={trackerError}
                title={"GenAI Application Tracker"}
                labelKey="key"
                valueKey="value"
              />
            </div>

            <div
              className="col-md-7 margin-top_div"
              style={{ minHeight: "300px", backgroundColor: "#f5f5f5" }}
            >
              <TimeseriesChart
                data={TrackerTimeseries}
                isLoaded={TimeseriesLoaded}
                error={TimeseriesError}
                title="Total Tracked Requests"
              />
            </div>
          </div>
          <div class="d-flex align-items-center">
            <h2 class="mb-0">GenAI Query Tracker</h2>{" "}
            <InfoTooltip
              tooltipText={
                "Understand exactly what questions AI is answering with your content."
              }
            />
          </div>
          <div className="row d-flex" style={{ alignItems: "stretch" }}>
            <div
              className="col-md-5 margin-top_div"
              style={{ minHeight: "400px", backgroundColor: "#f5f5f5" }}
            >
              <BarChart
                data={clusterShares}
                isLoaded={queriesLoaded}
                error={queriesError}
                title={"Query Groups (Clusters)"}
                labelKey="cluster_name"
                valueKey="share"
              />
            </div>
            <div className="col-md-7 margin-top_div">
              <KeyValueTable
                data={TopPerformingUrl}
                isLoaded={pagesLoaded}
                error={pagesError}
                columnRatio="95-5"
                column1Key="url"
                column1Title="Top Performing GenAI Pages"
                tooltipText="These pages are being used to serve Gen-AI queries"
                column2Title="Traffic"
                column2Key="traffic"
                defaultNumberOfRows={10}
              />
            </div>
          </div>
          <h2>Gen AI Gap Analysis</h2>
          <div className="row d-flex" style={{ alignItems: "stretch" }}>
            <div className="col-md-6 margin-top_div h-100">
              <QueryFilterTabs
                data={poorlyPerformedQueries}
                isLoaded={queriesLoaded}
                error={queriesError}
                columnKey={"query"}
                columnTitle={"Missing Content"}
                tooltipText={
                  "Query Topics not covered by your content - consider adding content on these topics"
                }
                defaultNumberOfRows={5}
              />
            </div>

            <div className="col-md-6 margin-top_div h-100">
              <QueryFilterTabs
                data={somewhatPerformedQueries}
                isLoaded={queriesLoaded}
                error={queriesError}
                columnKey={"query"}
                columnTitle={"Content Improvement"}
                tooltipText={
                  "Query Topics only partially addressed by your content - consider broadening and/or deepening your content on these topics"
                }
                defaultNumberOfRows={5}
              />
            </div>
          </div>
          <div className="row d-flex" style={{ alignItems: "stretch" }}>
            <div className="col-md-6 margin-top_div h-100">
              <SingleColumnTable
                data={ContentSuggestion.slice(0, 20)}
                isLoaded={contentSuggestionLoaded}
                error={contentSuggestionError}
                columnTitle={"Content Suggestion"}
                columnKey={"query"}
                tooltipText={
                  "Based on the frequency of inquiries and the performance of existing content, these are some of the most significant topics to consider adding to your website."
                }
                defaultNumberOfRows={5}
              />
            </div>
            <div className="col-md-6 margin-top_div h-100">
              <TimeseriesChart
                data={scaledRelevanceTimeSeries}
                isLoaded={relevanceTimeSeriesLoaded}
                error={relevanceTimeSeriesError}
                title="Relevance Scores Over Time"
                chartType="bar"
              />
            </div>
          </div>
          <h2>Gen AI Traffic Analysis </h2>
          <div className="row d-flex" style={{ alignItems: "stretch" }}>
            <div className="col-md-4 margin-top_div">
              <KeyValueTable
                data={GenAIApplicationTraffic}
                isLoaded={trafficLoaded}
                error={trafficError}
                column1Title="GenAI Application Traffic"
                column2Title="Traffic"
                columnRatio="80-20"
              />
            </div>

            <div className="col-md-8 margin-top_div">
              <KeyValueTable
                data={TrafficURLs}
                isLoaded={trafficURLLoaded}
                error={trafficURLError}
                columnRatio="95-5"
                column1Title="GenAI Traffic to Pages"
                tooltipText="Trafffic to these pages are from Gen-AI citation"
                column2Title="Traffic"
                defaultNumberOfRows={5}
              />
            </div>
          </div>
          {/* <h2>Others </h2>
          <div className="row d-flex" style={{ alignItems: "stretch" }}>
            <div className="col-md-5 margin-top_div">
              <ThreeDGraph />
            </div>
          </div> */}
        </div>

        <br />
      </div>
    </div>
  );
};

export default Analytics;
