import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import Loading from "./LoadingAnimation";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const TimeseriesChart = ({
  data,
  isLoaded = true,
  error = null,
  title = "Timeseries Data",
  labelKey = "key",
  valueKey = "value",
  chartType = "area",
}) => {
  const getDatesInRange = (startDate, endDate) => {
    const dates = [];
    let currentDate = new Date(startDate);
    const end = new Date(endDate);

    while (currentDate <= end) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  const formatDateToMatch = (date) => {
    return date.toISOString().split("T")[0];
  };

  const processData = (rawData) => {
    if (!rawData.length) return [];

    const sortedData = [...rawData].sort(
      (a, b) => new Date(a[labelKey]) - new Date(b[labelKey])
    );

    const startDate = new Date(sortedData[0][labelKey]);
    const endDate = new Date(sortedData[sortedData.length - 1][labelKey]);

    const allDates = getDatesInRange(startDate, endDate);

    const dataMap = new Map(
      sortedData.map((item) => [item[labelKey], parseInt(item[valueKey])])
    );

    return allDates.map((date) => ({
      [labelKey]: formatDateToMatch(date),
      [valueKey]: dataMap.get(formatDateToMatch(date)) || 0,
    }));
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
  };

  const completeData = processData(data);

  const getChartColors = () => {
    switch (chartType) {
      case "bar":
        return {
          backgroundColor: "rgba(43, 134, 179, 0.7)",
          borderColor: "#2b86b3",
        };
      case "area":
        return {
          backgroundColor: "rgba(43, 134, 179, 0.1)",
          borderColor: "#2b86b3",
        };
      default:
        return {
          borderColor: "#2b86b3",
          backgroundColor: "transparent",
        };
    }
  };

  const chartData = {
    labels: completeData.map((item) => formatDate(item[labelKey])),
    datasets: [
      {
        data: completeData.map((item) => item[valueKey]),
        ...getChartColors(),
        borderWidth: 2,
        fill: chartType === "area",
        tension: chartType === "line" || chartType === "area" ? 0.3 : 0,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `Count: ${context.formattedValue}`,
          title: (contexts) =>
            formatDate(completeData[contexts[0].dataIndex][labelKey]),
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: { size: 10 },
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#f0f0f0",
        },
        ticks: {
          font: { size: 10 },
        },
      },
    },
  };

  const ChartComponent = chartType === "bar" ? Bar : Line;

  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        minHeight: "300px",
      }}
    >
      <b>{title}</b>
      {error ? (
        <p className="text-red-500" style={{ marginTop: "10px" }}>
          Sorry! An error occurred while fetching the data.
        </p>
      ) : isLoaded && data.length === 0 ? (
        <p className="text-gray-500" style={{ marginTop: "10px" }}>
          No data available
        </p>
      ) : isLoaded ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <div style={{ width: "100%", height: "300px" }}>
            <ChartComponent data={chartData} options={options} />
          </div>
        </div>
      ) : (
        <p style={{ marginTop: "10px" }}>
          <Loading />
        </p>
      )}
    </div>
  );
};

export default TimeseriesChart;
